import React from 'react';
import Section from './Section';
import SectionHeader from './SectionHeader';
import SectionButton from './SectionButton';
import SectionAction from './SectionAction';
import './PostSection.scss';

function PostSection(props) {
  const aosProps = {};
  for (const key of Object.keys(props)) {
    if (key.indexOf('data-aos') === 0) {
      aosProps[key] = props[key];
    }
  }

  return (
    <Section
      color={props.color}
      size={props.size}
      backgroundImage={props.backgroundImage}
      backgroundImageSrc={props.backgroundImageSrc}
      backgroundImageOpacity={props.backgroundImageOpacity}
      classes="PostSection"
      {...aosProps}
    >
      <div className="container">
        <div
          className="columns is-vcentered is-desktop is-8 is-variable"
          style={{ flexDirection: props.reversed ? 'row-reverse' : null }}
        >
          <div className="column">
            <figure className="HeroSection__image image">{props.image}</figure>
          </div>
          <div className="column is-6-desktop">
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              pretitle={props.pretitle}
              text={props.text}
              size={2}
              spaced={true}
            />
            <div className="buttons is-inline-flex">
              {props.actionText && (
                <SectionAction
                  parentColor={props.color}
                  to={props.actionURL}
                  outlined={true}
                >
                  {props.actionText}
                </SectionAction>
              )}
              {props.buttonText && (
                <SectionButton
                  parentColor={props.color}
                  onClick={props.buttonOnClick}
                >
                  {props.buttonText}
                </SectionButton>
              )}
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default PostSection;
