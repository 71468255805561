import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import HeroSection from '../components/HeroSection';
import styles from '../styles/global.scss';

function PageHeadSection(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          backgroundCityImage: file(relativePath: { eq: "cityscape.jpg" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <HeroSection
            color="primary"
            size="medium"
            backgroundImage={
              props.image ? (
                props.image
              ) : (
                <Img fluid={data.backgroundCityImage.childImageSharp.fluid} />
              )
            }
            backgroundImageOpacity={0.3}
            backgroundImageColor={styles.primaryColor}
            title={props.title}
            beforeTitle={props.beforeTitle}
          />
        );
      }}
    />
  );
}

export default PageHeadSection;
