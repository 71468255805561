import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import get from 'lodash.get';
import { parseISO, format } from 'date-fns';

import Layout from '../components/Layout';
import PageHeadSection from '../components/PageHeadSection';
import PostSection from '../components/PostSection';
import SEO from '../components/SEO';
import linkResolver from '../util/prismicLinkResolver';

export const query = graphql`
  {
    prismic {
      posts: allBlog_posts(sortBy: date_DESC) {
        edges {
          node {
            title
            extract
            date
            _meta {
              id
              uid
              type
            }
            cover_image
            cover_imageSharp {
              childImageSharp {
                fluid(maxWidth: 560, maxHeight: 400) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

const NewsPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="News" />
      <PageHeadSection title="News" />

      {data.prismic.posts.edges.map(({ node }, idx) => (
        <PostSection
          key={idx}
          color="white"
          size="medium"
          reversed={!(idx % 2)}
          backgroundImageSrc=""
          backgroundImageOpacity={1}
          title={get(node, 'title.0.text', '---')}
          subtitle={get(node, 'extract.0.text', '---')}
          pretitle={format(parseISO(node.date), 'MMMM d')}
          actionText="Read More"
          actionType="outlined"
          actionURL={linkResolver(node._meta)}
          image={
            <Img
              fluid={node.cover_imageSharp.childImageSharp.fluid}
              alt={node.cover_image.alt}
            />
          }
          data-aos="fade-up"
        />
      ))}
    </Layout>
  );
};

export default NewsPage;
